/**
 * Direttiva che costruisce tre input numerici distinti - rappresentanti ore, secondi e minuti - e torna un unico numero formato dalla somma dei tre valori
 */

export interface IHourValueDirectiveScope extends ng.IScope {
    lap_time: any;
	hourValue: any;
}
angular.module('app').directive("hourValue", ($window, $state, GlobalApplicationData, moment, $translate) => {
	return {
		restrict: 'AE',
		scope: {
			hourValue: '=',
			isScormDisabled: '&'
		},
		link: link,
		templateUrl: 'app/shared/hourValue/hourValue.html'
	};
	function link($scope: IHourValueDirectiveScope, element: JQuery, attrs: ng.IAttributes){
		$scope.$watch('hourValue', (newValue) => {
			let duration = moment.duration(newValue, 'seconds');
			$scope.lap_time = {
				minutes: duration.minutes(),
				seconds: duration.seconds()
			}
		});
		
		$scope.$watchCollection('lap_time', (newTime, oldTime) => {
			$scope.hourValue = moment.duration(newTime, 'seconds').asSeconds();
		});
	}
});